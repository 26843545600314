import React, { useState, useEffect } from "react";
import whiteLogo from "../../assets/WhiteLogo.png";
import classes from "./navbar.module.css";

const svgLogo = `<svg xmlns="http://www.w3.org/2000/svg" width="221" height="50" viewBox="0 0 221 50" fill="none">
  <g clip-path="url(#clip0_15054_2280)">
    <path d="M0.5 4.50876C0.5 2.26398 2.24068 0.5 4.59672 0.5C6.95276 0.5 8.69344 2.15629 8.69344 4.34799C8.69344 6.70046 7.00391 8.51752 4.59672 8.51752C2.18953 8.51752 0.5 6.75354 0.5 4.50876Z" fill="#475BD8"/>
    <path d="M7.77273 12.6765H1.37112V39.4349H7.77273V12.6765Z" fill="#475BD8"/>
    <path d="M40.6969 24.1005V39.4333H34.2952V24.9014C34.2952 20.1403 31.8881 17.8364 27.7913 17.8364C23.2342 17.8364 20.1621 20.5423 20.1621 25.904V39.4333H13.7605V12.6764H19.8536V16.1346C21.9539 13.679 25.2818 12.3761 29.2763 12.3761C35.8313 12.3761 40.6953 16.0345 40.6953 24.1021L40.6969 24.1005Z" fill="#475BD8"/>
    <path d="M105.423 28.0593H83.9674C84.7362 31.9679 88.0641 34.4736 92.8258 34.4736C95.898 34.4736 98.3051 33.5211 100.25 31.5675L103.681 35.4261C101.222 38.2821 97.3829 39.7852 92.6708 39.7852C83.5039 39.7852 77.5642 34.0231 77.5642 26.0556C77.5642 18.0882 83.5551 12.3761 91.6974 12.3761C99.8397 12.3761 105.524 17.8379 105.524 26.2058C105.524 26.7063 105.472 27.4586 105.421 28.0593H105.423ZM83.9162 23.9003H99.432C98.9205 20.0417 95.898 17.3859 91.6989 17.3859C87.4999 17.3859 84.5301 19.9917 83.9147 23.9003H83.9162Z" fill="#475BD8"/>
    <path d="M136.199 24.1005V39.4333H129.797V24.9014C129.797 20.1403 127.39 17.8364 123.293 17.8364C118.736 17.8364 115.664 20.5423 115.664 25.904V39.4333H109.262V12.6764H115.355V16.1346C117.454 13.679 120.784 12.3761 124.778 12.3761C131.333 12.3761 136.197 16.0345 136.197 24.1021L136.199 24.1005Z" fill="#475BD8"/>
    <path d="M141.063 4.50876C141.063 2.26398 142.803 0.5 145.159 0.5C147.515 0.5 149.256 2.15629 149.256 4.34798C149.256 6.70046 147.567 8.51752 145.159 8.51752C142.752 8.51752 141.063 6.75354 141.063 4.50876ZM141.934 12.6764H148.335V39.4349H141.934V12.6764Z" fill="#475BD8"/>
    <path d="M174.706 36.0268C172.658 38.4824 169.431 39.7838 165.899 39.7838C158.883 39.7838 154.12 36.0268 154.12 27.9592V12.6765H160.522V27.1068C160.522 31.968 162.878 34.2719 166.922 34.2719C171.378 34.2719 174.399 31.566 174.399 26.2058V12.6765L180.866 16.0831C182.913 13.6775 186.088 12.3746 189.827 12.3746C193.821 12.3746 197.149 13.8277 199.045 16.8354C201.246 14.0795 204.933 12.3746 209.286 12.3746C215.892 12.3746 220.5 16.033 220.5 24.1006V39.4334H214.098V24.903C214.098 20.1419 211.846 17.8364 207.954 17.8364C203.755 17.8364 200.836 20.5423 200.836 25.854V39.4334H194.435V24.903C194.435 20.1419 192.183 17.8364 188.29 17.8364C184.04 17.8364 181.173 20.5423 181.173 25.854V39.4334" fill="#475BD8"/>
    <path d="M73.7946 25.0258C73.776 21.4569 72.6104 18.5432 70.6651 16.3621C70.6015 16.2893 70.5364 16.218 70.4698 16.1483C67.7743 13.268 63.6822 11.7315 59.1003 11.7315C56.1599 11.7315 53.4195 12.3852 51.1223 13.6183C51.1223 13.6183 51.1213 13.6183 51.1192 13.6183C47.1 15.7752 44.434 19.7081 44.4045 25.0243C44.403 25.0546 44.403 25.0849 44.403 25.1153C44.403 32.9614 50.9828 38.1199 59.1003 38.1199C61.9028 38.1199 64.5223 37.4889 66.7513 36.3453C69.0608 35.1622 70.9503 33.427 72.1888 31.2733C72.2213 31.2156 72.2539 31.158 72.2849 31.1004C73.2521 29.3515 73.7961 27.3343 73.7961 25.1153C73.7961 25.0849 73.7961 25.0546 73.7961 25.0243L73.7946 25.0258ZM59.0988 32.5306C54.2875 32.4867 50.8542 29.391 50.8542 24.8635C50.8542 20.3861 54.2875 17.3404 59.0988 17.2964C63.9101 17.3389 67.3434 20.3861 67.3434 24.8635C67.3434 29.391 63.9101 32.4867 59.0988 32.5306Z" fill="#475BD8"/>
    <path d="M70.6666 44.8906C70.6031 44.8178 70.538 44.7465 70.4713 44.6768C67.7758 41.7965 63.6838 40.26 59.1019 40.26C56.1615 40.26 53.421 40.9137 51.1239 42.1468C51.1239 42.1468 51.1229 42.1468 51.1208 42.1468C48.2036 43.7121 45.9995 46.2117 44.9997 49.4985H51.8943C53.2583 47.237 55.8685 45.8523 59.1003 45.8234C62.3321 45.8523 64.9424 47.2355 66.3064 49.4985H73.2195C72.691 47.7178 71.8137 46.1753 70.6666 44.8891V44.8906Z" fill="#475BD8"/>
    <path d="M63.8758 36.6538L58.0496 34.5787L54.6397 43.7461L60.4659 45.8211L63.8758 36.6538Z" fill="#475BD8"/>
    <path d="M54.8254 25.5324C55.8954 25.5324 56.7629 24.6836 56.7629 23.6365C56.7629 22.5894 55.8954 21.7405 54.8254 21.7405C53.7553 21.7405 52.8878 22.5894 52.8878 23.6365C52.8878 24.6836 53.7553 25.5324 54.8254 25.5324Z" fill="#475BD8"/>
    <path d="M72.7986 12.6286L66.9724 10.5535L64.5422 17.087L70.3684 19.162L72.7986 12.6286Z" fill="#475BD8"/>
    <path d="M68.2997 14.1583L61.9152 8.09131H71.0324L77.4154 14.1583H68.2997Z" fill="#475BD8"/>
    <path d="M77.4155 14.1583H76.1754V17.1918H77.4155V14.1583Z" fill="#475BD8"/>
  </g>
  <defs>
    <clipPath id="clip0_15054_2280">
      <rect width="220" height="49" fill="white" transform="translate(0.5 0.5)"/>
    </clipPath>
  </defs>
</svg>`

const Navbar = (props) => {
  const [scrolled, setScrolled] = useState(props.scrolled || false);
  const [navState, setNavState] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 40) {
        setScrolled(true);
      } else {
        setScrolled(props.scrolled || false);
      }
    };
  }, []);

  const changeNavState = () => {
    setNavState((prev) => !prev);
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={scrolled ? classes.navbarScrolled : classes.navbar}>
      <div
      dangerouslySetInnerHTML={{__html: svgLogo}}
        className={scrolled ? classes.logoImageScrolled : classes.logoImage}
        alt="logo"
        src={svgLogo}
      />

      <ul
        style={{ margin: scrolled ? "auto" : "0px" }}
        className={classes.navlist}
      >
        <li onClick={() => (props.scrolled ? {} : scrollToSection("top"))}>
          {props.scrolled ? (
            <a
              href="https://www.ingeniumedu.com/"
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </a>
          ) : (
            "Home"
          )}
        </li>
        <li onClick={() => scrollToSection("features")}>Features</li>
        <li onClick={() => scrollToSection("faq")}>FAQs</li>
      </ul>
      <p className={scrolled ? classes.navmobileScrolled : classes.navmobile}>
        <ion-icon name="call"></ion-icon>

        <a
          style={{ textDecoration: "none", color: "#3C53E1" }}
          href="tel:7827552194"
        >
          +91-7827552194
        </a>
      </p>

      <div onClick={changeNavState} className={classes.mobilenavicon}>
        <ion-icon name="reorder-three-outline"></ion-icon>
      </div>

      <div
        style={navState ? { opacity: "1" } : { opacity: "0" }}
        className={
          navState
            ? [classes.mobnavcontainer, classes.withHeight].join(" ")
            : classes.mobnavcontainer
        }
      >
        <ul>
          <li onClick={() => (props.scrolled ? {} : scrollToSection("top"))}>
            {props.scrolled ? (
              <a
                href="https://www.ingeniumedu.com/"
                style={{ textDecoration: "none", color: "white" }}
              >
                Home
              </a>
            ) : (
              "Home"
            )}
          </li>
          <li onClick={() => scrollToSection("features")}>Features</li>
          <li onClick={() => scrollToSection("faq")}>FAQs</li>
          <li>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="tel:7827552194"
            >
              <ion-icon name="call"></ion-icon> +91-7827552194
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
