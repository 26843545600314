import React from "react";
import Navbar from "../Navbar/Navbar";
import Row from "react-bootstrap/Row";
import "../Footer/footer.css";

const Terms = () => {
  
  return (
    <>
      <Navbar scrolled />
      <div  className="TermsPage__rightsection" style={{marginTop: '100px', marginLeft: '10px', marginRight: '10px'}}>
          <h2>Agreement To Our Legal Terms</h2>
          <p>
            We are GAPSPASM TECHNOLOGY SOLUTIONS PVT. LTD. ( "Company," "we,"
            "us," "our" ) , a company registered in India at 6 B/143, Awas Vikas
            Colony , Farrukhabad , Uttar Pradesh 209625 .
          </p>
          <p>
            We operate the website https://ingeniumedu.com (the "Site"), the mobile
            application Ingenium (the "App"), as well as any other related
            products and services that refer or link to these legal terms (the
            "Legal Terms") (collectively, the "Services").
          </p>
         
          <p>
            You can contact us by phone at 7827552194, email at
            info@ingeniumedu.com, or by mail to 2nd Floor, D9, The Office Pass,
            Sector 3, Noida, Uttar Pradesh 201301, India.
          </p>
          <p>
            These Legal Terms constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ("you"),
            and GAPSPASM TECHNOLOGY SOLUTIONS PVT. LTD., concerning your access
            to and use of the Services. You agree that by accessing the
            Services, you have read, understood, and agreed to be bound by all
            of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
            TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
            YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <p>
            Supplemental terms and conditions or documents that may be posted on
            the Services from time to time are hereby expressly incorporated
            herein by reference. We reserve the right, in our sole discretion,
            to make changes or modifications to these Legal Terms at any time
            and for any reason. We will alert you about any changes by updating
            the "Last updated" date of these Legal Terms, and you waive any
            right to receive specific notice of each such change. It is your
            responsibility to periodically review these Legal Terms to stay
            informed of updates. You will be subject to, and will be deemed to
            have been made aware of and to have accepted, the changes in any
            revised Legal Terms by your continued use of the Services after the
            date such revised Legal Terms are posted.
          </p>
          <p>
            The Services are intended for users who are at least 13 years of
            age. All users who are minors in the jurisdiction in which they
            reside (generally under the age of 18) must have the permission of,
            and be directly supervised by, their parent or guardian to use the
            Services. If you are a minor, you must have your parent or guardian
            read and agree to these Legal Terms prior to you using the Services.
          </p>
          <p>
            We recommend that you print a copy of these Legal Terms for your
            records.
          </p>
        </div>
      <div className="TermsPage__lastsection mx-2">
        <div className="TermsPage__Sevice">
          <h2>1. Our Services</h2>
          <p>
            The information provided when using the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </p>
        </div>
        <div className="TermsPage__IPRights">
          <h2>2. Intellectual Property Rights</h2>
          <h3>Our intellectual property</h3>
          <p>
            We are the owner or the licensee of all intellectual property rights
            in our Services, including all source code, databases,
            functionality, software, website designs, audio, video, text,
            photographs, and graphics in the Services (collectively, the
            "Content"), as well as the trademarks, service marks, and logos
            contained therein (the "Marks"). Our Content and Marks are protected
            by copyright and trademark laws (and various other intellectual
            property rights and unfair competition laws) and treaties in the
            United States and around the world. The Content and Marks are
            provided in or through the Services "AS IS" for your personal,
            non-commercial use only.
          </p>
          <h3>Your use of our Services</h3>
          <p>
            Subject to your compliance with these Legal Terms, including the
            "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive,
            non-transferable, revocable license to: access the Services; and
            download or print a copy of any portion of the Content to which you
            have properly gained access. solely for your personal,
            non-commercial use. Except as set out in this section or elsewhere
            in our Legal Terms, no part of the Services and no Content or Marks
            may be copied, reproduced, aggregated, republished, uploaded,
            posted, publicly displayed, encoded, translated, transmitted,
            distributed, sold, licensed, or otherwise exploited for any
            commercial purpose whatsoever, without our express prior written
            permission. If you wish to make any use of the Services, Content, or
            Marks other than as set out in this section or elsewhere in our
            Legal Terms, please address your request to: mohit@ingeniumedu.com.
            If we ever grant you the permission to post, reproduce, or publicly
            display any part of our Services or Content, you must identify us as
            the owners or licensors of the Services, Content, or Marks and
            ensure that any copyright or proprietary notice appears or is
            visible on posting, reproducing, or displaying our Content. We
            reserve all rights not expressly granted to you in and to the
            Services, Content, and Marks. Any breach of these Intellectual
            Property Rights will constitute a material breach of our Legal Terms
            and your right to use our Services will terminate immediately.
          </p>
          <h3>Your submissions and contributions</h3>
          <p>
            Please review this section and the "PROHIBITED ACTIVITIES" section
            carefully prior to using our Services to understand the (a) rights
            you give us and (b) obligations you have when you post or upload any
            content through the Services.<br/> <span>Submissions:</span> By directly
            sending us any question, comment, suggestion, idea, feedback, or
            other information about the Services ("Submissions"), you agree to
            assign to us all intellectual property rights in such Submission.
            You agree that we shall own this Submission and be entitled to its
            unrestricted use and dissemination for any lawful purpose,
            commercial or otherwise, without acknowledgment or compensation to
            you.<br/><span>Contributions:</span> The Services may invite you to
            chat, contribute to, or participate in blogs, message boards, online
            forums, and other functionality during which you may create, submit,
            post, display, transmit, publish, distribute, or broadcast content
            and materials to us or through the Services, including but not
            limited to text, writings, video, audio, photographs, music,
            graphics, comments, reviews, rating suggestions, personal
            information, or other material ("Contributions"). Any Submission
            that is publicly posted shall also be treated as a Contribution.
          </p>

          <h3>
            You understand that Contributions may be viewable by other users of
            the Services.
          </h3>
          <p>
            <span>
              When Contributions, you grant us a license (including use of your
              name, trademarks, and logos):
            </span>
            you post By posting any Contributions, you grant us an unrestricted,
            unlimited, irrevocable, perpetual, non-exclusive, transferable,
            royalty-free, fully-paid, worldwide right, and license to: use,
            copy, reproduce, distribute, sell, resell, publish, broadcast,
            retitle, store, publicly perform, publicly display, reformat,
            translate, excerpt (in whole or in part), and exploit your
            Contributions (including, without limitation, your image, name, and
            voice) for any purpose, commercial, advertising, or otherwise, to
            prepare derivative works of, or incorporate into other works, your
            Contributions, and to sublicense the licenses granted in this
            section. Our use and distribution may occur in any media formats and
            through any media channels. This license includes our use of your
            name, company name, and franchise name, as applicable, and any of
            the trademarks, service marks, trade names, logos, and personal and
            commercial images you provide.{" "}
            <br/><span>You are responsible for what you post or upload:</span>
            By sending us Submissions and/or posting Contributions through any
            part of the Services or making Contributions accessible through the
            Services by linking your account through the Services to any of your
            social networking accounts, you: confirm that you have read and
            agree with our "PROHIBITED ACTIVITIES" and will not post, send,
            publish, upload, or transmit through the Services any Submission nor
            post any Contribution that is illegal, harassing, hateful, harmful,
            defamatory, obscene, bullying, abusive, discriminatory, threatening
            to any person or group, sexually explicit, false, inaccurate,
            deceitful, or misleading; to the extent permissible by applicable
            law, waive any and all moral rights to any such Submission and/or
            Contribution; warrant that any such Submission and/or Contributions
            are original to you or that you have the necessary rights and
            licenses to submit such Submissions and/or Contributions and that
            you have full authority to grant us the above-mentioned rights in
            relation to your Submissions and/or Contributions; and warrant and
            represent that your Submissions and/or Contributions do not
            constitute confidential information. You are solely responsible for
            your Submissions and/or Contributions and you expressly agree to
            reimburse us for any and all losses that we may suffer because of
            your breach of (a) this section, (b) any third party’s intellectual
            property rights, or (c) applicable law.{" "}
          <br/>  <span>We may remove or edit your Content:</span> Although we have no
            obligation to monitor any Contributions, we shall have the right to
            remove or edit any Contributions at any time without notice if in
            our reasonable opinion we consider such Contributions harmful or in
            breach of these Legal Terms. If we remove or edit any such
            Contributions, we may also suspend or disable your account and
            report you to the authorities.
            </p>
            <div className="TermsPage__userRepresentation">
            <h2>3. User Representations</h2>
            <p>By using the Services, you represent and warrant that: (1)
            all registration information you submit will be true, accurate,
            current, and complete; (2) you will maintain the accuracy of such
            information and promptly update such registration information as
            necessary; (3) you have the legal capacity and you agree to comply
            with these Legal Terms; (4) you are not under the age of 13; (5) you
            are not a minor in the jurisdiction in which you reside, or if a
            minor, you have received parental permission to use the Services;
            (6) you will not access the Services through automated or non-human
            means, whether through a bot, script or otherwise; (7) you will not
            use the Services for any illegal or unauthorized purpose; and (8)
            your use of the Services will not violate any applicable law or
            regulation.</p> 
            </div>
          
        </div>
      </div>
      <Row className="footer_copyright">
        <p className="copyright_text">
          <strong>Copyright © Ingenium | 2022</strong>
        </p>
      </Row>
    </>
  );
};

export default Terms;
